import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clients, { thunkHandler } from "../../services/api";
import { toast } from "react-toastify";

const initialState = {
  status: "idle",
  contracts: null,
};

export const getContracts = createAsyncThunk(
  "contract/fetch",
  (_, thunkAPI) => {
    const response = thunkHandler(
      clients.default.client({
        method: "GET",
        url: `/contracts`,
      }),
      thunkAPI
    );
    return response;
  }
);

export const searchContracts = createAsyncThunk(
  "contract/search",
  (q, thunkAPI) => {
    const response = thunkHandler(
      clients.default.client({
        method: "POST",
        url: `/contracts/search`,
        data: {
          artist_name: q,
        },
      }),
      thunkAPI
    );
    return response;
  }
);

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    cleanup: (state, action) => {
      state.status = "idle";
      state.contracts = null;
    },
  },
  extraReducers: {
    [getContracts.pending]: (state) => {
      state.status = "loading";
    },
    [getContracts.fulfilled]: (state, action) => {
      state.contracts = action.payload.data;
      state.status = "succeeded";
    },
    [getContracts.rejected]: (state, action) => {
      state.state = "failed";
      toast.error(action.payload.data.message);
    },
    [searchContracts.pending]: (state) => {
      state.status = "loading";
    },
    [searchContracts.fulfilled]: (state, action) => {
      state.contracts = action.payload.data;
      state.status = "succeeded";
    },
    [searchContracts.rejected]: (state, action) => {
      state.state = "failed";
      toast.error(action.payload.data.message);
    },
  },
});

export const { cleanup } = contractSlice.actions;

export default contractSlice.reducer;
