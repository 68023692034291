import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import ArtistTeamComponent from "../../../components/artist-team/ArtistTeam";

import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { viewArtistUseStyles } from "../../../custom-mui-style/custom-mui-styles";

const ArtistTeam = () => {
  const user = useSelector((state) => state.auth.user);
  const styles = viewArtistUseStyles();

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        rowSpacing={1}
        // columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        sm={12}
        lg={12}
        xl={12}
        md={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classess.page__topSec__greeting}>
            Welcome Back
          </Typography>
          <Typography className={classess.page__topSec__userName}>
            {/* Wayne! */}
            {user.role === "artist"
              ? user.meta_data.name
              : user?.firstName + "!" || "N/A"}
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mt={-1.3}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        >
          <ArtistTeamComponent />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArtistTeam;
