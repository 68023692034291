import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import ResetPasswordForm from "../../components/reset-password-form/ResetPasswordForm";

const ResetPassword = () => {
  return (
    <Box
      varient="div"
      component="div"
      sx={{ display: "flex", pb: { xs: 10, lg: 0 } }}
      className={classess.page}
    >
      <Box
        varient="div"
        component="div"
        className={classess.page__form_page_container}
      >
        <Box
          varient="div"
          component="div"
          className={classess.page__form_page_container__login}
        >
          <ResetPasswordForm />
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
