import React, { useState } from "react";
import classess from "./style.module.scss";
import {
  Box,
  Divider,
  // FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import SaveBtn from "../../saveBtn/saveBtn";
// import CustomTwoTabs from "../../custom-two-tabs/custom-two-tabs";
import CustomThreeTabs from "../../custom-three-tabs/custom-three-tabs";
// import { CustomCheckbox } from "../../../custom-mui-style/custom-mui-styles";

const BuyOut = ({
  artist,
  Verify_component_here,
  Customize_component_here,
  Uplaod_component_here,
  isLoading,
}) => {
  const [containerExpanded, setContainerExpanded] = useState([
    false,
    false,
    false,
  ]);

  const toggleExpansion = (index) => {
    const updatedExpanded = containerExpanded.map((expanded, i) =>
      i === index ? !expanded : false
    );
    setContainerExpanded(updatedExpanded);
  };
  const data = [
    {
      name: "1. Historical Music",
      function: () => toggleExpansion(0),
      component: Verify_component_here,
      button_save: <SaveBtn saveBtnCss={classess.saveBtn} artist={artist} />,
    },
    {
      name: "2. Customize",
      function: () => toggleExpansion(1),
      component: Customize_component_here,
    },

    {
      name: "3. Upload",
      function: () => toggleExpansion(2),
      component: Uplaod_component_here,
    },
  ];
  return (
    <Box className={classess.page}>
      <Box className={classess.page__top}>
        <Box className={classess.page__top__heading}>
          {containerExpanded.some((expanded) => expanded)
            ? "Funding Dashboard"
            : "Start a deal"}
        </Box>
        <Box>
          <CustomThreeTabs
            text_0={"music"}
            text_1={"Customize"}
            text_2={"upload"}
            btn_0={containerExpanded[0]}
            btn_1={containerExpanded[1]}
            btn_2={containerExpanded[2]}
            click_0={() => toggleExpansion(0)}
            click_1={() => toggleExpansion(1)}
            click_2={() => toggleExpansion(2)}
            padding={"8px 20px"}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <Divider className={classess.page__divider} />
      <Box className={classess.page__container}>
        <Box className={classess.page__container__containerList}>
          {data.map((item, index) => (
            <ListItems
              index={index}
              containerExpanded={containerExpanded[index]}
              name={item.name}
              button_save={item.button_save}
              check_box={item.check_box}
              toggle_btn={item.function}
              component={item.component}
              isLoading={isLoading}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const ListItems = ({
  index,
  containerExpanded,
  name,
  button_save,
  component,
  toggle_btn,
  isLoading,
}) => {
  return (
    <Box
      key={index}
      className={classess.page__container__containerList__list}
      style={{
        maxHeight: containerExpanded ? "100%" : "71px",
        backgroundColor: containerExpanded ? "transparent" : "",
        border: containerExpanded
          ? "2px solid var(--base-img-border-color)"
          : "2px solid var(--base-inner-container-background-color)",
        paddingBottom: "10px",
        cursor: containerExpanded ? "default" : "pointer",
      }}
      onClick={isLoading || containerExpanded ? null : toggle_btn}
    >
      <Box
        className={classess.page__container__containerList__list__listContent}
      >
        <Box>
          <Typography
            className={
              classess.page__container__containerList__list__listContent__heading
            }
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
          }}
        >
          {containerExpanded && button_save}
          <IconButton
            className={
              classess.page__container__containerList__list__listContent__listContentIcon
            }
            onClick={isLoading ? null : toggle_btn}
          >
            {containerExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </IconButton>
        </Box>
      </Box>

      <Divider
        className={classess.page__divider}
        sx={{
          display: containerExpanded ? "block !important" : "none !important",
        }}
      />

      <Box sx={{ mt: 3 }}>{containerExpanded && component}</Box>
    </Box>
  );
};

export default BuyOut;
