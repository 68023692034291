import React from "react";
import classes from "./style.module.scss"; // Corrected typo
import { Box } from "@mui/material";

const CustomThreeTabs = ({
  btn_0,
  btn_1,
  btn_2,
  click_0,
  click_1,
  click_2,
  text_0,
  text_1,
  text_2,
}) => {
  const buttons = [
    { btn: btn_0, click: click_0, text: text_0 },
    { btn: btn_1, click: click_1, text: text_1 },
    { btn: btn_2, click: click_2, text: text_2 },
  ];

  return (
    <Box className={classes.page}>
      <Box className={classes.page__tabs_container}>
        {buttons.map((button, index) => (
          <button
            key={index}
            style={{
              backgroundColor: button.btn
                ? "var(--base-tabs-active-background)"
                : "",
            }}
            onClick={button.click}
          >
            {button.text}
          </button>
        ))}
      </Box>
    </Box>
  );
};

export default CustomThreeTabs;
