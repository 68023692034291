import React from "react";
import classess from "./style.module.scss";
import { Box } from "@mui/material";
const CustomFourTabs = ({
  btn_0,
  btn_1,
  btn_2,
  btn_3,
  click_0,
  click_1,
  click_2,
  click_3,
  text_0,
  text_1,
  text_2,
  text_3,
  isLoading,
}) => {
  return (
    <Box className={classess.page}>
      <Box className={classess.page__tabs_container}>
        <button
          style={{
            backgroundColor: btn_0 ? "var(--base-tabs-active-background)" : "",
          }}
          onClick={click_0}
          disabled={isLoading}
        >
          {text_0}
        </button>
        <button
          style={{
            backgroundColor: btn_1 ? "var(--base-tabs-active-background)" : "",
          }}
          onClick={click_1}
          disabled={isLoading}
        >
          {text_1}
        </button>
        <button
          style={{
            backgroundColor: btn_2 ? "var(--base-tabs-active-background)" : "",
          }}
          onClick={click_2}
          disabled={isLoading}
        >
          {text_2}
        </button>
        <button
          style={{
            backgroundColor: btn_3 ? "var(--base-tabs-active-background)" : "",
          }}
          onClick={click_3}
          disabled={isLoading}
        >
          {text_3}
        </button>
      </Box>
    </Box>
  );
};

export default CustomFourTabs;
