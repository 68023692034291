import React from "react";
import Box from "@mui/material/Box";
import classess from "./style.module.scss";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { forgotpassword } from "../../redux/slice/auth";
import { config } from "../../enviorment/enviorment";
import { useNavigate } from "react-router-dom";
import CmgLogo from "../../assets/cmg-logo.png";
import { CustomAuthTextField } from "../../custom-mui-style/custom-mui-styles";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();

    const payload = {
      email: event.target.email.value.toLocaleLowerCase(),
      host: config.CURRENT_HOST,
    };

    dispatch(
      forgotpassword({
        data: payload,
      })
    );
    event.target.email.value = "";
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  return (
    <Box varient="div" component="div" className={classess.page}>
      <Box varient="div" component="div" className={classess.page__logo}>
        <img src={CmgLogo} alt="logo" className={classess.page__logo__img} />
        <h1 className={classess.page__logo__text}>Forget Password</h1>
      </Box>
      <div className={classess.page__horizontalLine} />

      <Box varient="div" component="div" className={classess.page__form}>
        <form
          action=""
          className={classess.page__form__main}
          onSubmit={onSubmit}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__form__main__field_container}
          >
            <label
              className={classess.page__form__main__field_container__label}
            >
              E-mail Address
            </label>
            <FormControl variant="filled" color="info" fullWidth>
              <CustomAuthTextField
                id="component-filled"
                name="email"
                type="text"
                required
                placeholder="Email Address"
              />
            </FormControl>
          </Box>
          <Box
            varient="div"
            component="div"
            className={classess.page__form__main__action}
          >
            <Button
              type="submit"
              className={classess.page__form__main__action__btn}
              variant="contained"
            >
              Send Email
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPasswordForm;
