import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import ForgotPasswordForm from "../../components/forgot-password-form/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <Box
      varient="div"
      component="div"
      sx={{ display: "flex", pb: { xs: 10, lg: 0 } }}
      className={classess.page}
    >
      <Box
        varient="div"
        component="div"
        className={classess.page__form_page_container}
      >
        <Box
          varient="div"
          component="div"
          className={classess.page__form_page_container__login}
        >
          <ForgotPasswordForm />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
