import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DefaultAvatar from "../../assets/default-avatar.webp";
import { Typography } from "@mui/material";
const SearchAutcomplete = ({ list, search, onInput, handleSearchItem }) => {
  return (
    <Box varient="div" component="div" className={classess.container}>
      <Box
        varient="div"
        component="div"
        className={classess.container__search_box}
      >
        <input
          name="search"
          placeholder="Search by artist name"
          type="search"
          value={search}
          onInput={onInput}
          autoComplete="off"
          className={classess.container__search_box__search_input}
        />
      </Box>
      {list && list.length ? (
        <ul className={classess.container__list}>
          <Typography className={classess.container__list__found}>
            {list?.length} Artist Found
          </Typography>
          {list.map((item, idx) => (
            <li key={idx} className={classess.container__list__list_item}>
              <Box
                component="div"
                varient="div"
                className={classess.container__list__list_item__contains}
                onClick={() => handleSearchItem(item)}
              >
                <LazyLoadImage
                  alt={item?.name}
                  effect="blur"
                  src={item?.image ? item?.image : DefaultAvatar}
                  className={
                    classess.container__list__list_item__contains__avatar
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = DefaultAvatar;
                  }}
                />
                <span
                  className={
                    classess.container__list__list_item__contains__title
                  }
                >
                  {item.name}
                </span>
              </Box>
            </li>
          ))}
        </ul>
      ) : null}
    </Box>
  );
};

export default SearchAutcomplete;
