import React, { useState } from "react";
import classess from "./style.module.scss";
import { Box, Typography } from "@mui/material";
import ContractsTable from "./contracts-table/contracts-table";
import PaymentTable from "./payment-table/payment-table";
import CustomTwoTabs from "../custom-two-tabs/custom-two-tabs";
const PendingApproval = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec}>
        <Box>
          <Typography className={classess.page__topSec__heading}>
            Pending Approvals
          </Typography>
        </Box>
        <Box className={classess.page__topSec__tabs_container}>
          <CustomTwoTabs
            text_0={"CONTRACTS"}
            text_1={"PAYMENTS"}
            btn_0={activeTab === 1}
            btn_1={activeTab === 2}
            click_0={() => handleTabClick(1)}
            click_1={() => handleTabClick(2)}
            padding={"10px 28px"}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 1.5 }}>
        <Box>
          {activeTab === 1 && (
            <Box>
              <ContractsTable />
            </Box>
          )}

          {activeTab === 2 && (
            <Box>
              <PaymentTable />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PendingApproval;
