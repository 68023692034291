import React, { useEffect } from "react";
import classess from "./style.module.scss";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
export const TrackGraph = ({ options, series, type, height }) => {
  return (
    <Box className={classess.page}>
      <div id="chart" className={classess.page__chart}>
        <ReactApexChart
          options={options}
          series={series}
          type={type}
          height={height}
        />
      </div>
    </Box>
  );
};

export default TrackGraph;
