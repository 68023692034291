import React from "react";
import classess from "./style.module.scss";

const GradientButton = ({ text, ...others }) => {
  return (
    <React.Fragment>
      <button className={classess.gradient_button} {...others}>
        {text}
      </button>
    </React.Fragment>
  );
};

export default GradientButton;
