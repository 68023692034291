import React, { useState } from "react";
import Box from "@mui/material/Box";
import classess from "./style.module.scss";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { Artistlogin } from "../../redux/slice/auth";
import { useNavigate } from "react-router-dom";
import { _fetchToken } from "../../utils/spotifyApiServiceHandler";
import { toast } from "react-toastify";
import { createNewSession } from "../../services/session";
import IconButton from "@mui/material/IconButton";
import { FiEyeOff, FiEye } from "react-icons/fi";
import {
  CustomAuthTextField,
  CustomCheckbox,
} from "../../custom-mui-style/custom-mui-styles";

const ArtistLoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const fetchSpotifyToken = async () => await _fetchToken();
  let rememberMe = null;

  try {
    rememberMe = JSON.parse(localStorage.getItem("rememberMe"));
  } catch (error) {
    console.error("Error parsing rememberMe from localStorage", error);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = { email: email.toLowerCase(), password };

    try {
      console.log("payload", data);
      await _fetchToken();
      const response = await dispatch(Artistlogin({ data }));
      await createNewSession(response.payload.data.accessToken);
      if (remember) {
        localStorage.setItem("rememberMe", JSON.stringify({ email, password }));
      }
    } catch (err) {
      toast.error(err.message);
    }
    setEmail("");
    setPassword("");
  };

  return (
    <Box varient="div" component="div" className={classess.page}>
      <Box className={classess.customForm}>
        <Box varient="div" component="div" className={classess.page__form}>
          <form
            action=""
            className={classess.page__form__main}
            onSubmit={onSubmit}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__form__main__field_container}
            >
              <span
                className={classess.page__form__main__field_container__label}
              >
                USERNAME
              </span>
              <FormControl variant="filled" color="info" fullWidth>
                <CustomAuthTextField
                  id="component-filled"
                  defaultValue={rememberMe ? rememberMe.email : ""}
                  name="email"
                  type="text"
                  // className={classess.page__form__main__field_container__input}
                  required
                  placeholder="Username"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Box>

            <Box
              varient="div"
              component="div"
              className={classess.page__form__main__field_container}
              mt={2}
            >
              <span
                className={classess.page__form__main__field_container__label}
              >
                PASSWORD
              </span>
              <FormControl
                variant="filled"
                color="info"
                fullWidth
                sx={{ position: "relative" }}
              >
                <CustomAuthTextField
                  defaultValue={rememberMe ? rememberMe.password : ""}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  // className={classess.page__form__main__field_container__input}
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Box
                  varient="div"
                  component="div"
                  sx={{
                    position: "absolute",
                    right: "15px",
                    top: "0px",
                    color: "#62666c",
                  }}
                >
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <FiEye fontSize={22} cursor="pointer" />
                    ) : (
                      <FiEyeOff fontSize={22} cursor="pointer" />
                    )}
                  </IconButton>
                </Box>
              </FormControl>
            </Box>

            <Box
              varient="div"
              component="div"
              className={classess.page__form__main__form_action}
            >
              <FormControlLabel
                control={
                  <CustomCheckbox
                    onChange={(event) => setRemember(event.target.checked)}
                  />
                }
                label="Remember me"
                sx={{
                  color: "white",
                  fontWeight: "500",
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />

              <Button
                onClick={() => navigate("/forgot-password")}
                variant="text"
                className={classess.page__form__main__action__reset_btn}
              >
                <u>Reset Password?</u>
              </Button>
            </Box>

            <Box
              varient="div"
              component="div"
              className={classess.page__form__main__action}
            >
              <Button
                type="submit"
                className={classess.page__form__main__action__btn}
                variant="contained"
              >
                Sign In
              </Button>
            </Box>

            <Box
              varient="div"
              component="div"
              className={classess.page__form__main__dnthaveaccount}
              sx={{
                fontSize: "14px",
                mt: 2,
              }}
            >
              <span>
                Don’t have an account yet?
                <span
                  className={classess.page__form__main__dnthaveaccount__spantwo}
                >
                  <span>Click here to request access</span>
                </span>
              </span>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ArtistLoginForm;
