import React, { useState } from "react";

import appleMusicIcon from "../../assets/social/social-icon1.png";
import youtubeIcon from "../..//assets/social/social-icon2.png";
import spotifyIcon from "../../assets/social/social-icon3.png";
import deezerIcon from "../../assets/social/social-icon5.png";
import amazonMusicIcon from "../../assets/social/social-icon6.png";
import tidalIcon from "../../assets/social/social-icon7.png";
import tiktokIcon from "../../assets/social/social-icon8.png";
import instagramIcon from "../../assets/social/social-icon9.png";
import twitterIcon from "../../assets/social/social-icon10.png";
import { RxCross2 } from "react-icons/rx";
import { Box, Tooltip, IconButton, Chip, Modal } from "@mui/material/";
import classess from "./style.module.scss";
const Platform = ({ socialLink }) => {
  const [showRemaining] = useState(false);

  const MAX_DISPLAY_LINKS = 5;
  const links = socialLink || [];
  const displayedLinks = showRemaining
    ? links
    : links.slice(0, MAX_DISPLAY_LINKS);
  const remainingLinks = links.slice(MAX_DISPLAY_LINKS);

  const [modalOpen, setModalOpen] = useState(false);

  const handleShowModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "199px",
    bgcolor: "#222C41",
    borderRadius: "12px",
    boxShadow: 24,
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <React.Fragment>
      <div className={classess.page__artist__box__platforms__socials}>
        {displayedLinks?.map((item) => {
          return (
            <React.Fragment>
              <Tooltip
                title={item?.source}
                placement="top"
                arrow
                enterDelay={100}
              >
                <img
                  style={{ cursor: "pointer" }}
                  key={item.id}
                  src={
                    (item.source === "deezer" && deezerIcon) ||
                    (item.source === "spotify" && spotifyIcon) ||
                    (item.source === "amazon" && amazonMusicIcon) ||
                    (item.source === "tidal" && tidalIcon) ||
                    (item.source === "tiktok" && tiktokIcon) ||
                    (item.source === "apple_music" && appleMusicIcon) ||
                    (item.source === "twitter" && twitterIcon) ||
                    (item.source === "instagram" && instagramIcon) ||
                    (item.source === "youtube" && youtubeIcon)
                  }
                  className={
                    classess.page__artist__box__platforms__socials__icons
                  }
                  alt={`${item.source}`}
                  onClick={() => openInNewTab(`${item.url}`)}
                />
              </Tooltip>
            </React.Fragment>
          );
        })}
        {remainingLinks.length > 0 && (
          <Tooltip title="Platforms" placement="top" arrow enterDelay={100}>
            <Chip
              label={`+${remainingLinks.length}`}
              className={classess.page__artist__box__platforms__socials__chip}
              onClick={handleShowModal}
            />
          </Tooltip>
        )}

        <div>
          {/* Modal */}
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className={classess.modalCss}>
              <Box className={classess.modalCss__heading}>
                Platform
                <Box className={classess.modalCss__heading__icon}>
                  <IconButton
                    className={classess.modalCss__heading__icon__inner}
                    onClick={handleCloseModal}
                  >
                    <RxCross2 />
                  </IconButton>
                </Box>
              </Box>
              <Box
                className={classess.modalCss__heading__content}
                sx={{
                  p: 3,
                }}
              >
                {socialLink?.map((item) => {
                  return (
                    <React.Fragment>
                      <Tooltip
                        title={item?.source}
                        placement="top"
                        arrow
                        enterDelay={100}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          key={item.id}
                          src={
                            (item.source === "deezer" && deezerIcon) ||
                            (item.source === "spotify" && spotifyIcon) ||
                            (item.source === "amazon" && amazonMusicIcon) ||
                            (item.source === "tidal" && tidalIcon) ||
                            (item.source === "tiktok" && tiktokIcon) ||
                            (item.source === "apple_music" && appleMusicIcon) ||
                            (item.source === "twitter" && twitterIcon) ||
                            (item.source === "instagram" && instagramIcon) ||
                            (item.source === "youtube" && youtubeIcon)
                          }
                          className={
                            classess.page__artist__box__platforms__socials__icons
                          }
                          alt={`${item.source}`}
                          onClick={() => openInNewTab(`${item.url}`)}
                        />
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
          </Modal>
        </div>
      </div>

      {/* <div
        className={classess.page__artist__box__platforms__socials__bigScreen}
      >
        {socialLink?.map((item) => {
          return (
            <React.Fragment>
              <Tooltip
                title={item?.source}
                placement="top"
                arrow
                enterDelay={100}
              >
                <img
                  style={{ cursor: "pointer" }}
                  key={item.id}
                  src={
                    (item.source === "deezer" && deezerIcon) ||
                    (item.source === "spotify" && spotifyIcon) ||
                    (item.source === "amazon" && amazonMusicIcon) ||
                    (item.source === "tidal" && tidalIcon) ||
                    (item.source === "tiktok" && tiktokIcon) ||
                    (item.source === "apple_music" && appleMusicIcon) ||
                    (item.source === "twitter" && twitterIcon) ||
                    (item.source === "instagram" && instagramIcon) ||
                    (item.source === "youtube" && youtubeIcon)
                  }
                  className={
                    classess.page__artist__box__platforms__socials__icons
                  }
                  alt={`${item.source}`}
                  onClick={() => openInNewTab(`${item.url}`)}
                />
              </Tooltip>
            </React.Fragment>
          );
        })}
      </div> */}
    </React.Fragment>
  );
};

export default Platform;
