import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import ArtistContract from "../../../components/artist-contract/ArtistContract";
import ArtistContractSign from "../../../components/artist-contract-sign/ArtistContractSign";
import { useParams } from "react-router-dom";
import { getItemToLocalStorage } from "../../../services/storage";
import { config as URLconfig } from "../../../enviorment/enviorment";
import axios from "axios";
const ContractDetails = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    const fetchArtistDeals = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/contracts/${id}`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        console.log("Response status:", response.status);
        console.log("Response data:", response);
        setActivities(response);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtistDeals();
  }, []);

  const openSign = () => {
    setOpen(true);
  };
  const closeSign = () => {
    setOpen(false);
  };
  return (
    <Container maxWidth="xxl" className="graphToolsHide">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={8}>
          <ArtistContract openSign={openSign} changeBg={open} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={4}>
          {open && <ArtistContractSign closeSign={closeSign} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContractDetails;
