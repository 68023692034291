import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import { Box, Typography } from "@mui/material";
import artistAdded from "../../assets/artistAdded.svg";
import dealsRevenue from "../../assets/dealsRevenue.svg";
import pendingDeals from "../../assets/pendingDeals.svg";
import pendingPayments from "../../assets/pendingPayments.svg";
import dealsClosed from "../../assets/dealsClosed.svg";
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import axios from "axios";
import { useSelector } from "react-redux";
import NewLoader from "../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import { abbreviateNumber } from "../../utils/helper";

const ArtistDealsWelcomeCards = ({ TotalDeals }) => {
  const [totalPendingDeals, setTotalPendingDeals] = useState(0);
  const [totalDealsNumber, setTotalDealsNumber] = useState(0);
  const [dealsCloseAndRevenue, setDealsCloseAndRevenue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalArtist, setTotalArtist] = useState(0);
  const storedToken = getItemToLocalStorage("accessToken");
  const user = useSelector((state) => state.auth.user);

  const getCurrentTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return "GOOD MORNING";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "GOOD AFTERNOON";
    } else {
      return "GOOD EVENING";
    }
  };

  const timeOfDay = getCurrentTimeOfDay();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const artistResponse = await axios.get(
          `${URLconfig.BASE_URL}/artists/total`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setTotalArtist(artistResponse.data.data);

        const dealRevenueResponce = await axios.get(
          `${URLconfig.BASE_URL}/contracts/dealsclosedandrevenue`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setDealsCloseAndRevenue(dealRevenueResponce.data.data);

        const pendingDealsResponse = await axios.get(
          `${URLconfig.BASE_URL}/payments/totalpending`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setTotalPendingDeals(pendingDealsResponse.data.data);

        const dealsNumberResponse = await axios.get(
          `${URLconfig.BASE_URL}/contracts/totalpending`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setTotalDealsNumber(dealsNumberResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, handle errors in the UI here.
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user.id, storedToken]);

  // {{baseUrl}}/api/v1/artists/{Artist_id}
  // total artist added by AnR

  const boxData = [
    {
      id: 1,
      icon: artistAdded,
      //   number: totalArtist,
      number: TotalDeals,
      dealName: "deals",
    },
    {
      id: 2,
      icon: dealsRevenue,
      number: "$" + abbreviateNumber("380000"),
      dealName: "advance recieved",
    },
  ];
  return (
    <Box className={classes.page}>
      <Box className={classes.page__topSec}>
        <Box>
          <Typography className={classes.page__topSec__greeting}>
            {/* GOOD MORNING, */}
            {timeOfDay}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.page__topSec__userName}>
            {/* Wayne! */}
            {user.role === "artist"
              ? user.meta_data.name
              : user?.firstName + "!" || "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.page__boxes}>
        {boxData.map((item) => (
          <Box key={item.id} className={classes.page__boxes__box}>
            <Box className={classes.page__boxes__box__icon}>
              <ReactSVG src={item.icon} />
            </Box>
            <Box className={classes.page__boxes__box__num}>
              {isLoading ? (
                <Box sx={{ width: "42px", height: "42px" }}>
                  <Lottie
                    animationData={NewLoader}
                    className={classes.page__loader}
                  />
                </Box>
              ) : item.number || item.number === 0 ? (
                item.number
              ) : (
                "0"
              )}
            </Box>
            <Box className={classes.page__boxes__box__dealName}>
              {item.dealName}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ArtistDealsWelcomeCards;
