import React, { useState } from "react";
import classess from "./style.module.scss";
import { Avatar, Box, Button, IconButton, Stack } from "@mui/material";
import {
  CustomSelect,
  CustomTextField,
  CustomMenuItem,
} from "../../custom-mui-style/custom-mui-styles";
import PhoneInput from "react-phone-number-input";
import { LoadingButton } from "@mui/lab";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import SpotifySvg from "../../assets/spotify.svg";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/icons/edit.svg";

const ArtistForm = ({
  handleSubmit,
  image,
  name,
  onInputName,
  country,
  telephone,
  setTelephone,
  email,
  onInputEmail,
  onInputCountry,
  allCountries,
  spotifyDisabled,
  spotify_id,
  onInputSpotifyId,
  isLoading,
  handleClose,
  adjust_height,
  avatar,
  handleProfileChange,
  //   editIcon,
  artist_name,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <Box className={classess.page}>
      <Box
        className={classess.page__fieldsContainer}
        sx={{ height: adjust_height }}
      >
        <form
          action=""
          className={classess.page__fieldsContainer__form}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {/* {avatar ? null : (
            <Box variant="div" component="div">
              <Avatar
                src={image}
                alt={name}
                className={classess.page__fieldsContainer__form__avatar}
              />
            </Box>
          )} */}
          <Box variant="div" component="div">
            <Avatar
              src={image}
              alt={name}
              className={classess.page__fieldsContainer__form__avatar}
            />
          </Box>
          <Stack
            direction="row"
            sx={{
              gap: { xs: 3, sm: 8, lg: 3 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__fieldsContainer__form__formfield}
              sx={{ width: "100%" }}
            >
              <label
                className={
                  classess.page__fieldsContainer__form__formfield__label
                }
              >
                Artist Name *
              </label>
              <CustomTextField
                autoComplete="off"
                value={name}
                maxLength={100}
                onInput={onInputName}
                placeholder="Enter Artist Name"
                type="text"
                required
              />
            </Box>
            <Box
              varient="div"
              component="div"
              className={classess.page__fieldsContainer__form__formfield}
              sx={{ width: "100%" }}
            >
              <label
                className={
                  classess.page__fieldsContainer__form__formfield__label
                }
              >
                Phone
              </label>
              <PhoneInput
                international
                className={
                  classess.page__fieldsContainer__form__formfield__phoneinput
                }
                defaultCountry={country}
                formatPhoneNumberIntl={telephone}
                placeholder="Enter phone number"
                value={telephone}
                name="telephone"
                onChange={setTelephone}
                autoComplete="off"
                required
                countryCallingCodeEditable={false}
                style={{
                  border: isFocused
                    ? "1px solid var(--base-input-focus-color)"
                    : "1px solid var(--base-border-line-color)",
                  transition: "all 0.3s ease-in-out",
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            sx={{
              gap: { xs: 3, sm: 8, lg: 3 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__fieldsContainer__form__formfield}
              sx={{ width: "100%" }}
            >
              <label
                className={
                  classess.page__fieldsContainer__form__formfield__label
                }
              >
                E-mail Address
              </label>
              <CustomTextField
                maxLength={80}
                value={email}
                onInput={onInputEmail}
                placeholder="Enter Email Address"
                type="email"
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            sx={{
              gap: { xs: 3, sm: 8, lg: 3 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            {" "}
            <Box
              varient="div"
              component="div"
              className={classess.page__fieldsContainer__form__formfield}
              sx={{ width: "100%" }}
            >
              <label
                className={
                  classess.page__fieldsContainer__form__formfield__label
                }
              >
                Country *
              </label>
              <CustomSelect
                value={country}
                onChange={onInputCountry}
                required
                displayEmpty
                onOpen={handleOpenSelect}
                onClose={handleCloseSelect}
                IconComponent={() =>
                  isOpen ? (
                    <IoIosArrowUp
                      className={
                        classess.page__fieldsContainer__form__formfield__select_icon
                      }
                    />
                  ) : (
                    <IoIosArrowDown
                      className={
                        classess.page__fieldsContainer__form__formfield__select_icon
                      }
                    />
                  )
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <CustomMenuItem value="">Select Country</CustomMenuItem>
                {allCountries && allCountries.length > 0
                  ? allCountries.map((item) => (
                      <CustomMenuItem
                        required
                        key={item.value}
                        value={item.value}
                      >
                        {item.label}
                      </CustomMenuItem>
                    ))
                  : null}
              </CustomSelect>
            </Box>
            <Box
              varient="div"
              component="div"
              className={classess.page__fieldsContainer__form__formfield}
              sx={{ width: "100%" }}
            >
              <label
                className={
                  classess.page__fieldsContainer__form__formfield__label
                }
              >
                <img
                  className={
                    classess.page__fieldsContainer__form__formfield__label__image
                  }
                  src={SpotifySvg}
                  alt="spotify"
                />{" "}
                <Box ml={1}>Spotify ID</Box>
              </label>
              <input
                className={
                  classess.page__fieldsContainer__form__formfield__input
                }
                readOnly={spotifyDisabled}
                value={spotify_id}
                onInput={onInputSpotifyId}
                placeholder="Enter Spotify Id"
                required
                style={{
                  border: "1px solid #5A7380",
                }}
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            sx={{
              gap: { xs: 3, sm: 8, lg: 15 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
              width: "100%",
              mt: 3,
            }}
          >
            <Box
              varient="div"
              component="div"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  lg: "50%",
                },
                marginLeft: "auto",
              }}
            >
              <Stack direction="row" gap={4}>
                <Button
                  type="button"
                  className={classess.page__fieldsContainer__form__cancel_btn}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  onClick={handleSubmit}
                  loading={isLoading}
                  loadingPosition="start"
                  variant="contained"
                  className={classess.page__fieldsContainer__form__submit_btn}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default ArtistForm;
