import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal"; // Import Modal
import { IconButton } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
const ResponsiveMessageModal = ({ open, onClose, heading, message }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "199px",
    bgcolor: "#222C41",
    borderRadius: "12px",
    boxShadow: 24,
    border: "none",
    outline: "none",

    // p: 4,
  };
  return (
    <div>
      {/* Modal */}
      <Modal
        open={open}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classess.modalCss}>
          <Box className={classess.modalCss__heading}>
            {heading}
            <Box className={classess.modalCss__heading__icon}>
              {/* <IconButton
                className={classess.modalCss__heading__icon__inner}
                onClick={onClose}
              >
                <RxCross2 />
              </IconButton> */}
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
              color: "white",
            }}
          >
            {message}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ResponsiveMessageModal;
