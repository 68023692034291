import React from "react";
import ReactApexChart from "react-apexcharts";
import classess from "./style.module.scss";

const Graph = () => {
  const [SoundCloudStates] = React.useState({
    series: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],
    options: {
      chart: {
        id: "SoundCloudStateschart2",
        type: "line",
        height: 350,
        foreColor: "#ff8800",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#ff8800"],

      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 10,
      },
    },

    //   Second Graph Starts

    seriesLine: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],

    optionsLine: {
      chart: {
        id: "SoundCloudStateschart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "SoundCloudStateschart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date("1 Jan 2022").getTime(),
            max: new Date("30 Jan 2022").getTime(),
          },
        },
      },
      colors: ["#ff8800"],
      stroke: {
        width: 2,
        curve: "smooth",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  var chart1 = new ReactApexChart(
    document.querySelector("#chart-area"),
    SoundCloudStates.options
  );
  chart1.render();

  var chart2 = new ReactApexChart(
    document.querySelector("#chart-bar"),
    SoundCloudStates.optionsLine
  );

  chart2.render();

  return (
    <div id="wrapper" className={classess.wrapper}>
      <div id="chart-line2">
        <ReactApexChart
          options={SoundCloudStates.options}
          series={SoundCloudStates.series}
          type="line"
          height={230}
        />
      </div>
      <div id="chart-line" className={classess.chart_line}>
        <ReactApexChart
          options={SoundCloudStates.optionsLine}
          series={SoundCloudStates.seriesLine}
          type="area"
          height={130}
        />
      </div>
    </div>
  );
};

export default Graph;

// Remove the following lines from this component and manage rendering in the parent component.
// const domContainer = document.querySelector('#app');
// ReactDOM.render(React.createElement(ApexChart), domContainer);
