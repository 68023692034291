import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import ArtitsDealWelcomeCards from "../../../components/artist-deals-Wellcome-Cards/ArtistDealsWelcomeCards";
import ArtistMyDeal from "../../../components/artist-my-deal/ArtistMyDeal";

import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ArtistDeals = () => {
  const user = useSelector((state) => state.auth.user);
  const [TotalDeals, setTotalDeals] = useState(0);

  return (
    <Container maxWidth="xxl" className="graphToolsHide">
      <Grid container spacing={2} className={classess.page}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ArtitsDealWelcomeCards TotalDeals={TotalDeals} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ArtistMyDeal setTotalDeals={setTotalDeals} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArtistDeals;
