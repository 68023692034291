import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LoginFormContainer from "../../components/login-form-container/login-form-container";

const LoginContainer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      varient="div"
      component="div"
      sx={{ display: "flex", pb: { xs: 10, lg: 0 } }}
      className={classess.page}
    >
      <Box
        varient="div"
        component="div"
        className={classess.page__form_page_container}
      >
        <Box
          varient="div"
          component="div"
          className={classess.page__form_page_container__login}
        >
          <LoginFormContainer />
        </Box>

        <Box
          varient="div"
          component="div"
          className={classess.page__form_page_container__loginContant}
        >
          <CallOutlinedIcon
            className={classess.page__form_page_container__loginContant__icon}
          />

          <span
            className={classess.page__form_page_container__loginContant__text}
          >
            24/7 Customer Support
          </span>
        </Box>

        <Box className={classess.page__form_page_container__details_container}>
          <span style={{ color: "#fff", cursor: "default" }}>
            Version 1.12.0
          </span>
          <span>Release Notes</span>
          <span>Terms & Agreement</span>
          <span>Privacy Right</span>
        </Box>

        <Box
          variant="caption"
          display="block"
          className={classess.page__form_page_container__ownership_container}
        >
          <span>
            Powered by BlackLion Inc {currentYear}. All Rights Reserved
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginContainer;
