import React from "react";
import classess from "./style.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import moment from "moment";
import { IconButton } from "@mui/material";
import DownloadIcon from "../../../assets/buttonsicons/DownloadIcon.png";

import {
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../../custom-mui-style/custom-mui-styles";

const TransferPaymentList = ({ props, transefered_payments }) => {
  const internationalNumberFormat = new Intl.NumberFormat("en-US");

  return (
    <Box className={classess.page} varient="div" component="div">
      <Box component="div" varient="div" className={classess.page__list}>
        <PaymentTableComponent
          transefered_payments={transefered_payments}
          internationalNumberFormat={internationalNumberFormat}
        />
      </Box>
    </Box>
  );
};

export default TransferPaymentList;

const PaymentTableComponent = ({
  transefered_payments,
  internationalNumberFormat,
}) => {
  return (
    <CustomTableContainer className={classess.table}>
      <CustomTable stickyHeader={true} aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader
              sx={{
                width: "150px",
                maxWidth: "150px",
              }}
            >
              TRANSACTION ID
            </CustomTableCellHeader>
            <CustomTableCellHeader>DATE</CustomTableCellHeader>
            <CustomTableCellHeader>MODE</CustomTableCellHeader>
            <CustomTableCellHeader>AMOUNT PAID</CustomTableCellHeader>
            <CustomTableCellHeader>PAID AGAINST</CustomTableCellHeader>
            <CustomTableCellHeader>STATUS</CustomTableCellHeader>
            <CustomTableCellHeader>RECEIPT</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody className={classess.table__brow}>
          {transefered_payments.map((transaction) => (
            <React.Fragment key={transaction.id}>
              <Box sx={{ m: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    width: "150px",
                    maxWidth: "150px",
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <span className={classess.table__row__transection_id}>
                    {transaction.transection_id}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classess.table__row__date}>
                    {moment(transaction.createdAt).format("MMM DD YYYY")}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classess.table__row__mode}>
                    {transaction.mode}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classess.table__row__mode}>
                    ${" "}
                    {internationalNumberFormat.format(transaction.amount_paid)}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classess.table__row__mode}>
                    {transaction.paid_against}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classess.table__row__mode}>
                    {transaction.status}
                  </span>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <span className={classess.table__row__mode}>
                    <IconButton
                      style={{
                        backgroundColor: "var(--base-action-icon-background)",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img
                        src={DownloadIcon}
                        alt="Eye"
                        style={{ height: "12px", width: "16px" }}
                      />
                    </IconButton>
                  </span>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
