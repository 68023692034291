import React from "react";

const Success = () => {
  return (
    <>
      <h1 style={{ marginLeft: "2rem", backgroundColor: "white" }}>Success </h1>
    </>
  );
};
export default Success;
