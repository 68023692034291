// React and Redux imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Axios for HTTP requests
import axios from "axios";

// Material-UI and custom components imports
import { Box, IconButton, Typography, Button } from "@mui/material";
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../../custom-mui-style/custom-mui-styles";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";

// Style, assets, and utility imports
import classess from "./style.module.scss";
import { getItemToLocalStorage } from "../../../services/storage";
import { config as URLconfig } from "../../../enviorment/enviorment";
import NewLoader from "../../lottie-loader/Loader.json";
import EyeIcon from "../../../assets/eye.svg";
import { addCommasToNumber } from "../../../utils/helper";

const PaymentTable = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const navigate = useNavigate();

  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPayments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/payments/pending?page=0&limit=10`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setPayments(response.data.data);
        console.log("Paymemnt", response.data.data);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <Box className={classess.page}>
      <PaymentTableComponent
        isLoading={isLoading}
        payments={payments}
        formatDate={formatDate}
      />
      <Button
        onClick={() => navigate("/blig/payments")}
        className={classess.page__button}
      >
        View All Payments
      </Button>
    </Box>
  );
};

const PaymentTableComponent = ({ isLoading, payments, formatDate }) => {
  const navigate = useNavigate();

  return (
    <CustomTableContainer className={classess.page__table}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            <Lottie
              animationData={NewLoader}
              className={classess.page__loader}
            />
          </Box>
        </Box>
      ) : (
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableCellHeader>Invoice No.</CustomTableCellHeader>

            <CustomTableCellHeader>Amount</CustomTableCellHeader>

            <CustomTableCellHeader>Type</CustomTableCellHeader>

            <CustomTableCellHeader>INITIATED ON</CustomTableCellHeader>

            <CustomTableCellHeader>ACTIONS</CustomTableCellHeader>
          </CustomTableHead>

          <CustomTableBody>
            {payments.length > 0 ? (
              payments.map((payment) => (
                <React.Fragment>
                  <Box sx={{ m: "1rem" }} />
                  <CustomTableRow key={payment._id}>
                    <CustomTableCell
                      sx={{
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                      }}
                    >
                      <Typography className={classess.page__heading1}>
                        {payment?._id.substring(0, 8).toUpperCase()}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading1}>
                        {"$"}
                        {addCommasToNumber(
                          parseInt(payment.artist_advance, 10) +
                            parseInt(payment.artist_marketing_budget, 10)
                        )}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading2}>
                        {/* {payment.created_by} */}
                        {"N/A"}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading2}>
                        {formatDate(payment.createdAt)}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell
                      sx={{
                        borderBottomRightRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      {/* {console.log("id", payment)} */}
                      <IconButton
                        className={classess.page__actionIcon}
                        onClick={() =>
                          navigate(`/blig/payment/${payment?._id}`)
                        }
                      >
                        <ReactSVG src={EyeIcon} />
                      </IconButton>
                    </CustomTableCell>
                  </CustomTableRow>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <Box sx={{ marginTop: "1rem" }} />
                <CustomTableRow>
                  <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                    <Typography className={classess.page__notFound}>
                      No payments found.
                    </Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            )}
          </CustomTableBody>
        </CustomTable>
      )}
    </CustomTableContainer>
  );
};

export default PaymentTable;
